<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <Menu type="3" />

            <div class="data_group mt24">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="isPopupAdd = true">添加楼层</el-button>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                    <el-table-column prop="sort" label="楼层排序" width="100px" align="center"></el-table-column>
                    <el-table-column prop="name" label="楼层名称" min-width="180px" align="center"></el-table-column>
                    <el-table-column label="楼层类型" min-width="180px" align="center">
                        <template slot-scope="scope">模板{{scope.row.type}}</template>
                    </el-table-column>
                    <el-table-column label="是否显示" min-width="180px" align="center">
                        <template slot-scope="scope">{{scope.row.isShow === 1 ? '是' : '否'}}</template>
                    </el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" min-width="180px"></el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" @click="toEdit(scope.row.id)">基本设置</el-button>
                            <el-button type="text" @click="toSetting(scope.row.id)">模板设置</el-button>
                            <el-button type="text" @click="delData(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>

        <popupAdd :isPopupAdd="isPopupAdd" @close="isPopupAdd = false" @success="toAddSuccess" />
        <popupEdit :isPopupEdit="isPopupEdit" :dataInfo="dataInfoEdit" @close="isPopupEdit = false, dataInfoEdit = {}" @success="toEditSuccess" />
        <popupSetting :isPopupEdit="isPopupSetting" :dataInfo="dataInfoSetting" @close="isPopupSetting = false, dataInfoSetting = {}" @success="toEditSuccess" />
    </div>
</template>

<script>
  import {getAdminStoreFloor, getAdminStoreFloorInfo, delAdminStoreFloor} from '../../../../api/admin/store';
  const Menu = () => import('../menu');
  const popupAdd = () => import('./add');
  const popupEdit = () => import('./edit');
  const popupSetting = () => import('./setting');
  const pagination = () => import('../../../../components/pagination/admin');
  export default {
    name: "adminStoreFloor",
    data () {
      return {
        type: '1',
        listQuery: {
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        isPopupAdd: false,
        isPopupEdit: false,
        isPopupSetting: false,
        dataInfoEdit: {},
        dataInfoSetting: {}
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList () {
        this.isRequestLoading = true;
        getAdminStoreFloor(this.listQuery).then(res => {
          this.isRequestLoading = false;
          this.isSearchLoading = false;
          if (res.code === 0) {
            this.listData = res.data.records;
            this.pages.total = res.data.total;
            this.pages.pages = res.data.pages;
          }
        }).catch(() => {
          this.isRequestLoading = false;
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toAddSuccess () {
        this.isPopupAdd = false;
        this.listQuery.page = 1;
        this.getList()
      },
      toEdit (id) {
        this.isPopupEdit = true;
        getAdminStoreFloorInfo(id).then(res => {
          if (res.code === 0) {
            this.dataInfoEdit = res.data
          }
        })
      },
      toEditSuccess () {
        this.dataInfoEdit = {};
        this.isPopupEdit = false;
        this.dataInfoSetting = {};
        this.isPopupSetting = false;
        this.getList()
      },
      toSetting (id) {
        this.isPopupSetting = true
        getAdminStoreFloorInfo(id).then(res => {
          if (res.code === 0) {
            this.dataInfoSetting = res.data
          }
        })
      },
      delData (id) {
        this.$confirm('此操作将永久删除该楼层, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delAdminStoreFloor(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '楼层删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
    },
    components: {
      Menu, popupAdd, popupEdit, popupSetting, pagination
    }
  }
</script>
